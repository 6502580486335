var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"master_item_create"},[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"create-edit-form"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title pr-1 mb-0"},[_vm._v(" Create Items ")])])],1)],1)],1),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('validation-observer',{ref:"itemsMasterCreateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',{staticClass:"px-3 py-2",attrs:{"no-body":""}},[_c('b-table-simple',{ref:"itemTable",staticClass:"position-relative has_padding",attrs:{"caption-top":"","responsive":""}},[_c('b-thead',{attrs:{"head-variant":"dark"}},[_c('b-tr',[_c('b-th',{staticStyle:{"width":"20px","max-width":"20px"}}),_c('b-th',{staticStyle:{"width":"120px","max-width":"120px"}},[_vm._v(" Item No ")]),_c('b-th',[_vm._v("Item Name")]),_c('b-th',{staticStyle:{"width":"130px","max-width":"130px"}},[_vm._v(" Unit ")]),_c('b-th',[_vm._v("Supplier")]),_c('b-th',{staticStyle:{"width":"160px","max-width":"160px"}},[_vm._v(" Status ")])],1)],1),_c('b-tbody',{staticClass:"white-body-background"},[_vm._l((_vm.itemFields),function(field,index){return _c('b-tr',{key:index,ref:"row",refInFor:true,staticClass:"item_master_fields"},[_c('b-td',{staticClass:"remove__field"},[_c('b-button',{staticClass:"mt-0 no-background",attrs:{"variant":"flat-primary","type":"button"},on:{"click":function($event){return _vm.removeItemField(index)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1),_c('b-td',{staticClass:"item_name_field"},[_c('b-form-group',{attrs:{"label-for":'h-items-master-itemNo' + index}},[_c('validation-provider',{attrs:{"name":"Item No","vid":"itemNo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'h-items-master-itemNo' + index,"placeholder":"Item No","readonly":"","state":(errors.length > 0 || field.itemNoInvalid) ? false : null,"name":"itemNo"},on:{"input":function($event){field.itemNoInvalid == true ? field.itemNoInvalid = false : null}},model:{value:(field.itemNo),callback:function ($$v) {_vm.$set(field, "itemNo", $$v)},expression:"field.itemNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(field.itemNoInvalid)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(field.itemNoInvalidMessage)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-td',{staticClass:"remark_field"},[_c('b-form-group',{attrs:{"label-for":'h-items-master-description' + index}},[_c('validation-provider',{attrs:{"name":"Item Name","vid":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":'h-items-master-description' + index,"placeholder":"Item Name","rows":"3","state":(errors.length > 0 || field.descriptionInvalid) ? false : null,"name":"placeholder"},on:{"input":function($event){field.descriptionInvalid == true ? field.descriptionInvalid = false : null}},model:{value:(field.description),callback:function ($$v) {_vm.$set(field, "description", $$v)},expression:"field.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(field.descriptionInvalid)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(field.descriptionInvalidMessage)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-td',{staticClass:"date_field"},[_c('validation-provider',{attrs:{"name":"unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":'autosuggest__input' + index,"state":(errors.length > 0) ? false : null}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredUnitOptions,"get-suggestion-value":_vm.getUnitSuggestionValue,"input-props":{id: ("autosuggest__input" + index), class: 'form-control', placeholder: 'E.g. kg'}},on:{"selected":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.setUnit.apply(void 0, args.concat( [index] ));
},"input":_vm.suggestOnInputChangeUnit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item))])]}}],null,true),model:{value:(field.unit),callback:function ($$v) {_vm.$set(field, "unit", $$v)},expression:"field.unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(field.unitInvalid)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(field.unitInvalidMessage)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-td',{staticClass:"item_field",staticStyle:{"width":"345px","max-width":"345px"}},[_c('validation-provider',{attrs:{"name":"Supplier","vid":"supplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":'h-items-master-input-supplier' + index,"state":(errors.length > 0 || field.statusInvalid) ? false : null}},[_c('v-select',{attrs:{"id":'h-items-master-input-supplier' + index,"label":"companyName","placeholder":"Select an option","options":_vm.vendorOptions,"reduce":function (companyName) { return companyName._id; },"clearable":false},model:{value:(field.supplier),callback:function ($$v) {_vm.$set(field, "supplier", $$v)},expression:"field.supplier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(field.supplierInvalid)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(field.supplierInvalidMessage)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-td',{staticClass:"date_field",staticStyle:{"width":"170px","max-width":"170px","min-width":"170px"}},[_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":'h-items-master-input-status' + index,"state":(errors.length > 0 || field.statusInvalid) ? false : null}},[_c('v-select',{attrs:{"id":'h-items-master-input-status' + index,"label":"title","options":_vm.statusOptions,"reduce":function (title) { return title.code; },"clearable":false},model:{value:(field.status),callback:function ($$v) {_vm.$set(field, "status", $$v)},expression:"field.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(field.statusInvalid)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(field.statusInvalidMessage)+" ")]):_vm._e()],1)]}}],null,true)})],1)],1)}),_c('b-tr',{staticClass:"button_row"},[_c('b-td'),_c('b-td',{staticStyle:{"padding-bottom":"14px !important"},attrs:{"colspan":"5"}},[(_vm.itemFields.length > 0)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"primary"},on:{"click":_vm.addCustomField}},[_c('span',{staticClass:"align-middle"},[_vm._v("Add New Item")])]):_vm._e()],1)],1)],2)],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary","to":{ name: 'purchasing-items-master-index' }}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Items")])],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }