<template>

  <div class="master_item_create">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="create-edit-form">
          <b-col cols="12">
            <h1 class="custom-header-title pr-1 mb-0">
              Create Items
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="itemsMasterCreateForm"
        #default="{invalid}"
      >
        <b-card
          class="px-3 py-2"
          no-body
        >
          <b-table-simple
            ref="itemTable"
            class="position-relative has_padding"
            caption-top
            responsive
          >
            <b-thead head-variant="dark">
              <b-tr>
                <b-th style="width:20px; max-width:20px;" />
                <b-th style="width:120px; max-width:120px;">
                  Item No
                </b-th>
                <b-th>Item Name</b-th>
                <b-th style="width:130px; max-width:130px;">
                  Unit
                </b-th>
                <b-th>Supplier</b-th>
                <b-th style="width:160px; max-width:160px;">
                  Status
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody class="white-body-background">
              <b-tr
                v-for="(field, index) in itemFields"
                :key="index"
                ref="row"
                class="item_master_fields"
              >
                <b-td class="remove__field">
                  <b-button
                    variant="flat-primary"
                    class="mt-0 no-background"
                    type="button"
                    @click="removeItemField(index)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class=""
                    />
                  </b-button>
                </b-td>
                <b-td class="item_name_field">
                  <b-form-group
                    :label-for="'h-items-master-itemNo' + index"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Item No"
                      vid="itemNo"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-items-master-itemNo' + index"
                        v-model="field.itemNo"
                        placeholder="Item No"
                        readonly
                        :state="(errors.length > 0 || field.itemNoInvalid) ? false : null"
                        name="itemNo"
                        @input="field.itemNoInvalid == true ? field.itemNoInvalid = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.itemNoInvalid"
                        class="text-danger"
                      >
                        {{ field.itemNoInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-td>
                <b-td class="remark_field">
                  <b-form-group
                    :label-for="'h-items-master-description' + index"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Item Name"
                      vid="description"
                      rules="required"
                    >
                      <b-form-textarea
                        :id="'h-items-master-description' + index"
                        v-model="field.description"
                        placeholder="Item Name"
                        rows="3"
                        :state="(errors.length > 0 || field.descriptionInvalid) ? false : null"
                        name="placeholder"
                        @input="field.descriptionInvalid == true ? field.descriptionInvalid = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.descriptionInvalid"
                        class="text-danger"
                      >
                        {{ field.descriptionInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-td>
                <b-td class="date_field">
                  <validation-provider
                    #default="{ errors }"
                    name="unit"
                    rules="required"
                  >
                    <b-form-group
                      :label-for="'autosuggest__input' + index"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <vue-autosuggest
                        v-model="field.unit"
                        :suggestions="filteredUnitOptions"
                        :get-suggestion-value="getUnitSuggestionValue"
                        :input-props="{id: `autosuggest__input${index}`, class: 'form-control', placeholder: 'E.g. kg'}"
                        @selected="(...args) => setUnit(...args, index)"
                        @input="suggestOnInputChangeUnit"
                      >
                        <template slot-scope="{suggestion}">
                          <span class="my-suggestion-item">{{ suggestion.item }}</span>
                        </template>
                      </vue-autosuggest>

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.unitInvalid"
                        class="text-danger"
                      >
                        {{ field.unitInvalidMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-td>
                <b-td
                  class="item_field"
                  style="width: 345px; max-width: 345px;"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Supplier"
                    vid="supplier"
                    rules="required"
                  >
                    <b-form-group
                      :label-for="'h-items-master-input-supplier' + index"
                      :state="(errors.length > 0 || field.statusInvalid) ? false : null"
                    >
                      <v-select
                        :id="'h-items-master-input-supplier' + index"
                        v-model="field.supplier"
                        label="companyName"
                        placeholder="Select an option"
                        :options="vendorOptions"
                        :reduce="companyName => companyName._id"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.supplierInvalid"
                        class="text-danger"
                      >
                        {{ field.supplierInvalidMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-td>
                <b-td
                  class="date_field"
                  style="width: 170px; max-width: 170px; min-width: 170px;"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Status"
                    vid="status"
                    rules="required"
                  >
                    <b-form-group
                      :label-for="'h-items-master-input-status' + index"
                      :state="(errors.length > 0 || field.statusInvalid) ? false : null"
                    >
                      <v-select
                        :id="'h-items-master-input-status' + index"
                        v-model="field.status"
                        label="title"
                        :options="statusOptions"
                        :reduce="title => title.code"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.statusInvalid"
                        class="text-danger"
                      >
                        {{ field.statusInvalidMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-td>
              </b-tr>
              <b-tr class="button_row">
                <b-td />
                <b-td
                  colspan="5"
                  style="padding-bottom: 14px !important;"
                >
                  <b-button
                    v-if="itemFields.length > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    @click="addCustomField"
                  >
                    <span class="align-middle">Add New Item</span>
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'purchasing-items-master-index' }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Items</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BTableSimple, BThead, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,

    vSelect,
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      unitTypeOptions: [
        { title: 'Pcs', code: 'Pcs' },
        { title: 'KG', code: 'KG' },
        { title: 'EA', code: 'EA' },
      ],
      unitOptions: process.env.VUE_APP_UNIT_LIST.split(','),
      itemFields: [],
      vendorOptions: [],
      statusOptions: [
        { title: 'Pending', code: 'pending' },
        { title: 'Published', code: 'published' },
        { title: 'Hidden', code: 'hidden' },
      ],
      count: 0,
      filteredUnitOptions: [],

      // validation rules
      required,
    }
  },

  created() {
    this.$http.get('purchase/vendors/vendor-options')
      .then(response => {
        this.vendorOptions = response.data.vendorOptions ?? []
        this.count = response.data.itemCount ?? 0

        this.itemFields.push({
          type: 'text',
          itemNo: `MLI${(this.count + 1).toString().padStart(6, '0')}`,
          description: '',
          unit: '',
          supplier: '',
          status: 'pending',
          statusInvalid: false,
          statusInvalidMessage: '',
          itemNoInvalid: false,
          itemNoInvalidMessage: '',
          descriptionInvalid: false,
          descriptionInvalidMessage: '',
          supplierInvalid: false,
          supplierInvalidMessage: '',
          unitInvalid: false,
          unitInvalidMessage: '',
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    addCustomField() {
      this.itemFields.push({
        type: 'text',
        itemNo: `MLI${(this.count + 1 + this.itemFields.length).toString().padStart(6, '0')}`,
        description: '',
        unit: '',
        supplier: '',
        status: 'pending',
        itemNoInvalid: false,
        itemNoInvalidMessage: '',
        statusInvalid: false,
        statusInvalidMessage: '',
        descriptionInvalid: false,
        descriptionInvalidMessage: '',
        supplierInvalid: false,
        supplierInvalidMessage: '',
        unitInvalid: false,
        unitInvalidMessage: '',
      })
    },
    removeItemField(index) {
      if (this.itemFields.length > 1) {
        this.itemFields.splice(index, 1)
        this.regenarateItemNo()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one row.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    suggestOnInputChangeUnit(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredData = this.unitOptions.filter(item => item.toLowerCase().indexOf(text.toLowerCase()) > -1)

      this.filteredUnitOptions = [{
        data: filteredData,
      }]
    },
    getUnitSuggestionValue(suggestion) {
      return suggestion.item
    },
    setUnit(suggestionItem, _suggestionIndex, index) {
      if (_suggestionIndex || _suggestionIndex === 0) {
        this.itemFields[index].unit = suggestionItem.item
      }
    },
    regenarateItemNo() {
      this.itemFields.forEach((task, key) => {
        this.itemFields[key].itemNo = `MLI${(this.count + key + 1).toString().padStart(6, '0')}`
      })
    },
    submitForm() {
      this.$refs.itemsMasterCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          const trimemdData = this.itemFields.map(field => {
            const properties = {
              itemNo: field.itemNo,
              description: field.description,
              unit: field.unit,
              supplier: field.supplier,
              status: field.status,
            }
            return properties
          })

          formData.append('fields', JSON.stringify(trimemdData))

          this.$http.post('purchase/items-master/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Successfully Created!',
                  html: 'The item(s) has been successfully saved and created.',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'purchasing-items-master-index' })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'fields') {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: validationError.msg || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
</style>
